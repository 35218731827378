import useGetEventFromApp from "hooks/useGetEventFromApp";
import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { permanentDataAtom } from "recoil/permanentDataAtom";
import { userAtom } from "recoil/userAtom";
import { useColorScheme as useMaterialColorTheme } from "@mui/material/styles";
import { Button, Stack, Typography, useColorScheme } from "@mui/joy";
import { useLocation } from "react-router-dom";
import { sendMessage } from "utils/webview";
import { deviceAtom } from "recoil/deviceAtom";
import useOpenTask from "hooks/useOpenTask";
import { localizationAtom } from "recoil/localizationAtom";
import i18n from "translation/i18n";
import dayjs from "dayjs";
import { USER_DETAIL_ENDPOINT, USER_DOMAIN } from "api/userApi";
import { useQueryClient } from "@tanstack/react-query";
import useNavigatorOnLine from "hooks/useNavigatorOnline";
import { setAuthHeaders } from "api";

export default function Settings({ children }: { children: React.ReactNode }) {
  const [locale, setLocaleState] = useRecoilState(localizationAtom);
  const queryClient = useQueryClient();

  const location = useLocation();
  // const [searchParams] = useSearchParams();
  // const localeParam = searchParams.get("locale");
  // useEffect(() => {
  //   if (localeParam) {
  //     setLocaleState(localeParam);
  //   } else {
  //     // 브라우저 언어 변경 이벤트를 수신하고 언어를 업데이트
  //     const handleLanguageChange = () => {
  //       setLocaleState(navigator.language);
  //     };
  //     window.addEventListener("languagechange", handleLanguageChange);
  //     return () => {
  //       window.removeEventListener("languagechange", handleLanguageChange);
  //     };
  //   }
  // }, [localeParam]);

  const openPayment = useOpenTask(`payment`);
  const setPermanantDataState = useSetRecoilState(permanentDataAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const setDeviceState = useSetRecoilState(deviceAtom);
  const parsedMessageFromApp = useGetEventFromApp();

  const { mode, setMode } = useColorScheme();
  const { mode: materialMode, setMode: setMaterialMode } =
    useMaterialColorTheme();

  const isNetworkConnected = useNavigatorOnLine();

  useEffect(() => {
    const localeWithDefault =
      locale.startsWith("ko") || locale.startsWith("en") ? locale : "en";
    i18n.changeLanguage(localeWithDefault);
    dayjs.locale(localeWithDefault);
  }, [locale]);

  useEffect(() => {
    sendMessage(
      JSON.stringify({
        event: "location",
        params: {
          pathname: location.pathname,
        },
      })
    );
  }, [location]);

  useEffect(() => {
    sendMessage(
      JSON.stringify({
        event: "ready",
      })
    );
    setMode("light");
    setMaterialMode("light");
  }, []);

  const handleMessageFromApp = (parsedMessageFromApp: {
    event: string;
    params: any;
  }) => {
    if (parsedMessageFromApp?.event === "isWebview") {
      setPermanantDataState((state) => ({ ...state, isWebview: true }));
    }
    if (parsedMessageFromApp?.event === "appVersion") {
      const appVersion = parsedMessageFromApp.params.version;
      setPermanantDataState((state) => ({ ...state, appVersion: appVersion }));
    }
    if (parsedMessageFromApp?.event === "mode") {
      const colorScheme = parsedMessageFromApp.params.colorScheme;
      if (mode !== colorScheme) {
        setMode(colorScheme);
      }
      if (materialMode !== colorScheme) {
        setMaterialMode(colorScheme);
      }
    }
    if (parsedMessageFromApp?.event === "homeIndicatorHeight") {
      const homeIndicatorHeight = parsedMessageFromApp.params.value;
      setPermanantDataState((state) => ({
        ...state,
        homeIndicatorHeight: homeIndicatorHeight,
      }));
    }
    if (parsedMessageFromApp?.event === "accessToken") {
      const accessToken = parsedMessageFromApp.params.value;
      setAuthHeaders(accessToken);
      setUser((user) => ({
        ...user,
        accessToken: accessToken,
      }));
    }
    if (parsedMessageFromApp?.event === "appId") {
      const appId = parsedMessageFromApp.params.value;
      setUser((data) => ({
        ...data,
        appId: appId,
      }));
    }
    if (parsedMessageFromApp?.event === "pushNotification_result") {
      const status = parsedMessageFromApp.params.status;
      if (status) {
        setDeviceState((user) => ({
          ...user,
          pushNotificationStatus: status,
        }));
      }
    }

    if (parsedMessageFromApp?.event === "open") {
      const tab = parsedMessageFromApp.params.tab;
      if (tab === "payment") {
        openPayment();
      }
    }
    if (parsedMessageFromApp?.event === "refetch_user") {
      queryClient.invalidateQueries([USER_DOMAIN, USER_DETAIL_ENDPOINT]);
    }
    if (parsedMessageFromApp?.event === "openUrl") {
      const url = parsedMessageFromApp.params.url;
      window.location.href = url;
    }
  };

  useEffect(() => {
    if (Array.isArray(parsedMessageFromApp)) {
      parsedMessageFromApp.forEach((each) => handleMessageFromApp(each));
    } else {
      if (parsedMessageFromApp) {
        handleMessageFromApp(parsedMessageFromApp);
      }
    }
  }, [parsedMessageFromApp]);

  return (
    <>
      {!isNetworkConnected ? (
        <Stack
          direction={"column"}
          sx={{
            backgroundColor: "background.level2",
            width: "100%",
            height: "100%",
          }}
          justifyContent="center"
          alignItems={"center"}
          spacing={1}
        >
          <Typography level="h4">인터넷이 연결되어 있지 않아요</Typography>
          <Button
          // onClick={(e) => {
          //   window.location.reload();
          // }}
          >
            {"다시 불러오기"}
          </Button>
        </Stack>
      ) : (
        children
      )}
    </>
  );
}