import React, { useEffect, useRef } from 'react'
import { Box, Stack } from "@mui/joy";
import { theme } from "styles/theme";
import CustomTypography from "components/common/CustomTypography";
import { useNavigate } from "react-router-dom";
import company from "static/image/onboarding/company.png";
import { BottomNextButton } from "components/common/BottomNextButton";
import { useMutation } from '@tanstack/react-query'
import { onboardingApi } from 'api/onboardingApi'

export default function Goal_12() {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate(`/onboarding_new/convention/1`);
  };

  const pageKey = "goal_12";

  const { mutate: savePage } = useMutation(
    (pageId: string) => onboardingApi.savePage(pageId),
    {
      onSuccess: () => {},
    }
  );

  function useEffectOnce(callback: () => void) {
    const ref = useRef(false);

    useEffect(() => {
      if (ref.current) return;
      ref.current = true;

      if (typeof callback === "function") {
        callback();
      }
    }, []);
  }

  useEffectOnce(() => {
    savePage(pageKey);
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "70vh" }}>
      <Stack
        spacing={1.5}
        sx={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <CustomTypography
          variant="Subtitle"
          fontWeight={700}
          sx={{
            color: theme.vars.palette.Content.Highlight,
          }}
        >
          {"공신력 있는 기관들과 함께합니다."}
        </CustomTypography>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Strong,
          }}
        >
          {
            "디스턴싱은 유수의 병원, 대학, 기업들과의 협업, 투자 및 연구로 인정을 받고 있습니다."
          }
        </CustomTypography>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={company}
            alt={"onboarding"}
            style={{
              maxWidth: "300px",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Stack>
      <BottomNextButton
        disabled={false}
        onClick={handleNext}
        text={"다음"}
        highlightBackground={true}
      />
    </Box>
  );
}