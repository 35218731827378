import useGetEventFromApp from "hooks/useGetEventFromApp";
import { useEffect, useState } from "react";
import useLogin from "./useLogin";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";

export default function useSafeproLogin() {
  const user = useRecoilValue(userAtom);
  const [isSafeproLoginRequired, setIsSafeproLoginRequired] =
    useState<boolean>(false);
  const [safeproMemberToken, setSafeproMemberToken] = useState<
    string | undefined
  >(undefined);

  const login = useLogin({
    url: `/user/login`,
    requestBody: JSON.stringify({
      idToken: safeproMemberToken,
      type: "safepro",
    }),
  });

  useEffect(() => {
    if (isSafeproLoginRequired && safeproMemberToken) {
      login();
      setIsSafeproLoginRequired(false);
    }
  }, [isSafeproLoginRequired]);

  const parsedMessageFromApp = useGetEventFromApp();
  useEffect(() => {
    if (parsedMessageFromApp?.event === "safepro_login") {
      const safeproMemberToken = parsedMessageFromApp.params.value;
      if (safeproMemberToken) {
        setSafeproMemberToken(safeproMemberToken);
        if (!user?.accessToken) {
          setIsSafeproLoginRequired(true);
        }
      }
    }
  }, [parsedMessageFromApp]);

  return isSafeproLoginRequired;
}