import { Box, Button, Stack, Textarea } from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import { acquisitionApi } from "api/acquisitionApi";
import useDetectIOS from "hooks/useDetectIOS";
import FullPageWithTitleAndButton from "pages/container/FullPageWithTitleAndButton";
import { useState } from "react";

export default function Acquisition({
  title,
  progress,
  actionAfterSubmit,
}: {
  title: string;
  progress: number;
  actionAfterSubmit: () => void;
}) {
  const { isIOS } = useDetectIOS();

  const [selectedChannel, setSelectedChannel] = useState<string>();
  const [etcDetail, setEtcDetail] = useState<string>("");
  const [recommendedBy, setRecommendedBy] = useState<string>("");

  const channelList = [
    { name: "브런치" },
    { name: "네이버 카페" },
    { name: "네이버 블로그" },
    { name: "디스턴싱 오픈카톡방" },
    { name: "디스턴싱 홈페이지" },
    { name: "인스타그램" },
    { name: "쓰레드" },
    { name: "유튜브" },
    { name: "트위터" },
    { name: `${isIOS ? "앱 스토어" : "플레이 스토어"}에서 둘러보다` },
    {
      name: "누군가의 추천",
      additionalInfo: recommendedBy,
      setAdditionalInfo: setRecommendedBy,
      placeholder: "누구에게 추천 받았는지 알려주세요",
    },
    {
      name: "기타",
      additionalInfo: etcDetail,
      setAdditionalInfo: setEtcDetail,
      placeholder: "어떻게 알고 오셨는지 알려주세요",
    },
  ];

  const selectedChannelData = channelList.find(
    (element) => element.name === selectedChannel
  );

  const { mutate: submit } = useMutation(
    () =>
      acquisitionApi.submit(
        selectedChannel || "",
        selectedChannelData?.additionalInfo
      ),
    {
      onSuccess: () => {
        actionAfterSubmit();
      },
    }
  );

  return (
    <FullPageWithTitleAndButton
      title={title}
      buttonText={"다음"}
      buttonDisabled={
        !selectedChannel ||
        (selectedChannelData?.additionalInfo !== undefined &&
          (selectedChannelData?.additionalInfo || "").length === 0)
      }
      progress={progress}
      content={
        <Stack
          direction={"column"}
          sx={{
            width: "100%",
            whiteSpace: "nowrap",
            flexWrap: "wrap",
            gap: 1,
            pb: "40px",
          }}
        >
          {channelList.map(
            (
              { name, additionalInfo, setAdditionalInfo, placeholder },
              optionIndex
            ) => (
              <Stack
                key={`channel_option_${name}`}
                sx={{ flexGrow: 1, gap: 1 }}
              >
                <Button
                  fullWidth
                  size="md"
                  variant={"outlined"}
                  color={selectedChannel === name ? "primary" : "secondary"}
                  value={name}
                  onClick={(e) => {
                    setSelectedChannel(name);
                  }}
                >
                  {name}
                </Button>
                {selectedChannel === name && additionalInfo !== undefined && (
                  <Textarea
                    value={additionalInfo}
                    onChange={(e) => {
                      setAdditionalInfo(e.target.value);
                    }}
                    placeholder={placeholder}
                    sx={{
                      px: "10px",
                      py: "12px",
                      minHeight: "50px",
                      width: "100%",
                      borderWidth: "1px",
                      borderColor: "divider",
                      boxShadow: "none",
                      backgroundColor: "white",
                      "--joy-focus-thickness": "0px",
                      "--variant-borderWidth": "0px",
                      "--Textarea-gap": "0px",
                    }}
                  ></Textarea>
                )}
              </Stack>
            )
          )}
        </Stack>
      }
      buttonClickAction={submit}
    ></FullPageWithTitleAndButton>
  );
}
