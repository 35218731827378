import { useMutation } from "@tanstack/react-query";
import { CompanyMemberConfirmType, paymentApi } from "api/paymentApi";

export function useConfirmCompanyMemberPayment() {
  return useMutation(
    async (payload: CompanyMemberConfirmType) => {
      return await paymentApi.confirmCompanyMember(payload);
    },
    {
      onSuccess: async (responseData, variables) => {},
      onError: (error) => {
        alert("결제가 실패했어요. 다시 시도해 주세요.");
      },
    }
  );
}