export function ProgressIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="10" cy="16.5" rx="8" ry="2.5" fill="#657EAB" />
      <path
        d="M8.90532 2.37873C8.96096 2.15615 9.16095 2 9.39039 2H17.7929C18.2383 2 18.4614 2.53857 18.1464 2.85355L15.2548 5.74517C15.1026 5.89735 15.0649 6.12984 15.1612 6.32233L16.6382 9.27639C16.8044 9.60884 16.5627 10 16.191 10H7.64039C7.3151 10 7.07642 9.69431 7.15532 9.37873L8.90532 2.37873Z"
        fill="#F56666"
      />
      <path
        d="M9 2L5.5 16"
        stroke="#A9D1DD"
        stroke-width="1.6"
        stroke-linecap="round"
      />
    </svg>
  );
}

export function RightArrow() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="20" height="20" rx="3" fill="none" />
      <path
        d="M8 16L12 11L8 6"
        stroke="#AFB6BA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ThoughtRecordIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_773_1706)">
        <path
          d="M6.41421 1.58579C6.78929 1.21071 7.29799 1 7.82843 1H16.5C17.0523 1 17.5 1.44772 17.5 2V18C17.5 18.5523 17.0523 19 16.5 19H3.5C2.94772 19 2.5 18.5523 2.5 18V6.32843C2.5 5.79799 2.71071 5.28929 3.08579 4.91421L6.41421 1.58579Z"
          fill="#DFEEF2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.2597 14.4934C5.30955 14.3547 5.38946 14.2287 5.49371 14.1244L16.9111 2.70722C17.3016 2.3167 17.9347 2.3167 18.3253 2.70723L19.2929 3.67485C19.6834 4.06537 19.6834 4.69854 19.2929 5.08906L7.87555 16.5062C7.7713 16.6105 7.64527 16.6904 7.50651 16.7402L4.59891 17.7847C4.36019 17.8704 4.1294 17.6396 4.21516 17.4009L5.2597 14.4934Z"
          fill="#FDC322"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.5 4.11814L16.911 2.70711C17.3016 2.31658 17.9347 2.31658 18.3253 2.70711L19.2929 3.67474C19.6834 4.06527 19.6834 4.69843 19.2929 5.08896L17.8818 6.5L15.5 4.11814Z"
          fill="#E292CA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.3354 14.2827L7.71723 16.6646L4.59891 17.7847C4.36019 17.8705 4.1294 17.6397 4.21516 17.4009L5.3354 14.2827Z"
          fill="#FFE59F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.6179 16.2827L5.72 17.3848L4.59891 17.7875C4.36019 17.8733 4.1294 17.6425 4.21516 17.4038L4.6179 16.2827Z"
          fill="#433C29"
        />
        <path d="M15.5 4.11816L17.8818 6.50002" stroke="#E292CA" />
        <path
          d="M4 6.5H8V2.5"
          stroke="#BFDFE8"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_773_1706">
          <rect width="20" height="20" rx="3" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SegmentationRecordIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_773_1706)">
        <path
          d="M6.41421 1.58579C6.78929 1.21071 7.29799 1 7.82843 1H16.5C17.0523 1 17.5 1.44772 17.5 2V18C17.5 18.5523 17.0523 19 16.5 19H3.5C2.94772 19 2.5 18.5523 2.5 18V6.32843C2.5 5.79799 2.71071 5.28929 3.08579 4.91421L6.41421 1.58579Z"
          fill="#DFEEF2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.2597 14.4934C5.30955 14.3547 5.38946 14.2287 5.49371 14.1244L16.9111 2.70722C17.3016 2.3167 17.9347 2.3167 18.3253 2.70723L19.2929 3.67485C19.6834 4.06537 19.6834 4.69854 19.2929 5.08906L7.87555 16.5062C7.7713 16.6105 7.64527 16.6904 7.50651 16.7402L4.59891 17.7847C4.36019 17.8704 4.1294 17.6396 4.21516 17.4009L5.2597 14.4934Z"
          fill="#FDC322"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.5 4.11814L16.911 2.70711C17.3016 2.31658 17.9347 2.31658 18.3253 2.70711L19.2929 3.67474C19.6834 4.06527 19.6834 4.69843 19.2929 5.08896L17.8818 6.5L15.5 4.11814Z"
          fill="#E292CA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.3354 14.2827L7.71723 16.6646L4.59891 17.7847C4.36019 17.8705 4.1294 17.6397 4.21516 17.4009L5.3354 14.2827Z"
          fill="#FFE59F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.6179 16.2827L5.72 17.3848L4.59891 17.7875C4.36019 17.8733 4.1294 17.6425 4.21516 17.4038L4.6179 16.2827Z"
          fill="#433C29"
        />
        <path d="M15.5 4.11816L17.8818 6.50002" stroke="#E292CA" />
        <path
          d="M4 6.5H8V2.5"
          stroke="#BFDFE8"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_773_1706">
          <rect width="20" height="20" rx="3" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function MeditationIcon() {
  return (
    // <svg
    //   width="16"
    //   height="15"
    //   viewBox="0 0 16 15"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M1.5 8.53418L2.43995 8.24414C3.06967 8.04982 3.56536 7.56107 3.76853 6.93415V6.93415C4.03902 6.0995 4.8159 5.53402 5.69326 5.53957C7.33849 5.54998 9.28883 5.57331 10.5805 5.59023C11.4319 5.60138 12.1808 6.15022 12.451 6.95773V6.95773C12.6561 7.57067 13.1441 8.0472 13.7617 8.23778L14.7222 8.53418"
    //     stroke="white"
    //     stroke-width="1.3"
    //     stroke-linecap="round"
    //   />
    //   <path
    //     d="M9.5 3C9.5 3.38042 9.5 5.5 9.5 5.5H6.5C6.5 5.5 6.5 3.82843 6.5 3C6.5 2.17157 7.17157 1.5 8 1.5C8.82843 1.5 9.5 2.17157 9.5 3Z"
    //     stroke="white"
    //     stroke-width="1.3"
    //     stroke-linecap="round"
    //   />
    //   <path
    //     d="M8.11046 12.0556C8.11046 12.0556 6.89168 13.6111 5.38824 13.6111C3.8848 13.6111 2.66602 12.9147 2.66602 12.0556C2.66602 11.1964 3.8848 10.5 5.38824 10.5"
    //     stroke="white"
    //     stroke-width="1.3"
    //     stroke-linecap="round"
    //   />
    //   <path
    //     d="M8.11073 12.0558C8.11073 12.0558 9.32951 10.5002 10.833 10.5002C12.3364 10.5002 13.5552 11.1967 13.5552 12.0558C13.5552 12.9149 12.3364 13.6113 10.833 13.6113"
    //     stroke="white"
    //     stroke-width="1.3"
    //     stroke-linecap="round"
    //   />
    // </svg>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_773_1720)">
        <path
          d="M5.34464 6.37094C7.93395 3.52167 12.2016 2.84997 14.7886 2.72124C16.0884 2.65656 17.1499 3.62122 17.2095 4.92126C17.3281 7.50873 17.0665 11.821 14.4772 14.6702C11.8879 17.5195 7.62027 18.1912 5.03328 18.3199C3.73349 18.3846 2.67197 17.42 2.61238 16.1199C2.49377 13.5324 2.75534 9.22021 5.34464 6.37094Z"
          fill="#69AA4B"
        />
        <path
          d="M6.26172 14.0986L18.1819 1.20745"
          stroke="#4F9031"
          stroke-width="1.6"
          stroke-linecap="round"
        />
        <path
          d="M9.26367 6.73193L13.7727 5.91448"
          stroke="#4F9031"
          stroke-width="1.6"
          stroke-linecap="round"
        />
        <path
          d="M6.63672 10.1812L10.5463 9.47238"
          stroke="#4F9031"
          stroke-width="1.6"
          stroke-linecap="round"
        />
        <path
          d="M13.3184 10.481L13.7809 5.92181"
          stroke="#4F9031"
          stroke-width="1.6"
          stroke-linecap="round"
        />
        <path
          d="M10.1523 13.4316L10.5534 9.47863"
          stroke="#4F9031"
          stroke-width="1.6"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_773_1720">
          <rect width="20" height="20" rx="3" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function LearningActivityIcon() {
  return (
    // <svg
    //   width="12"
    //   height="15"
    //   viewBox="0 0 12 15"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M1 9V3.5C1 2.39543 1.89543 1.5 3 1.5H10.5C10.7761 1.5 11 1.72386 11 2V10C11 10.2761 10.7761 10.5 10.5 10.5H2.5C1.67157 10.5 1 11.1716 1 12V12C1 12.8284 1.67157 13.5 2.5 13.5H11"
    //     stroke="white"
    //     stroke-width="1.3"
    //     stroke-linecap="round"
    //   />
    //   <path
    //     d="M1 7.5L1 12"
    //     stroke="white"
    //     stroke-width="1.3"
    //     stroke-linecap="round"
    //   />
    //   <path
    //     d="M11 9L11 13.5"
    //     stroke="white"
    //     stroke-width="1.3"
    //     stroke-linecap="round"
    //   />
    // </svg>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.5 1.5C3.84315 1.5 2.5 2.84315 2.5 4.5V15.5C2.5 17.1569 3.84315 18.5 5.5 18.5H16.5C17.0523 18.5 17.5 18.0523 17.5 17.5C17.5 16.9477 17.0522 16.4999 16.4999 16.4999H6.3C5.85817 16.4999 5.5 16.1417 5.5 15.6999C5.5 15.2581 5.85817 14.8999 6.3 14.8999H16.25C16.9404 14.8999 17.5 14.3403 17.5 13.6499V2.5C17.5 1.94772 17.0523 1.5 16.5 1.5H5.5Z"
        fill="#6489CA"
      />
      <rect x="5.5" y="4.5" width="9" height="1.6" rx="0.8" fill="#3B61A5" />
      <rect x="5.5" y="7.1001" width="6" height="1.6" rx="0.8" fill="#3B61A5" />
    </svg>
  );
}
