import { Box, Typography, styled, Theme } from "@mui/joy";
import { keyframes } from "@mui/system";
import React from "react";
import { SxProps } from '@mui/joy/styles/types'


/*
  0%~10%   : 텍스트가 화면에 그대로 표시 (왼쪽 정렬, 움직이지 않음)
  10%~90%  : 왼쪽→왼쪽  (-100%)까지 천천히 이동
  90%~100% : 이미 완전히 왼쪽 밖 (끝)에서 정지
*/
export const marqueeKeyframes = keyframes`
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(0);
  }
  90% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const MarqueeContainer = styled(Box)({
  position: "relative",
  width: "250px", // 예: 고정 너비 (또는 maxWidth)
  overflow: "hidden",
  whiteSpace: "nowrap",
});

/*
  애니메이션은 총 duration=12초로 예시.
  필요하다면 시간을 바꾸거나
  ease-in-out / linear 등 timing function을 조절하세요.
*/
const MarqueeText = styled(Typography)({
  display: "inline-block",
  animation: `${marqueeKeyframes} 12s linear infinite`,
});

interface MarqueeLabelProps {
  text: string;
  variant?: React.ComponentProps<typeof Typography>["variant"];
  sx?: SxProps;
  containerWidth?: string | number; // 가로 폭이 필요하다면
}

export default function MarqueeLabel({
  text,
  variant = "Body",
  sx,
  containerWidth = "250px", // 예시 기본값
}: MarqueeLabelProps) {
  return (
    <MarqueeContainer
      sx={{
        width: containerWidth,
      }}
    >
      <MarqueeText variant={variant} sx={sx}>
        {text}
      </MarqueeText>
    </MarqueeContainer>
  );
}