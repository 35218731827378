import { useSearchParams } from "react-router-dom";
import useFetch from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import { Button, Stack, Typography } from "@mui/joy";
import { CheckLottie } from "../../components/common/CheckLottie";

export default function NewsletterSettings() {
  const [searchParams] = useSearchParams();

  const emailToken = searchParams.get("u");

  const [loading, setLoading] = useState<boolean>(false);
  const [disable, setDisabled] = useState<boolean>(false);
  const [allowed, setAllowed] = useState<boolean>(false);

  useEffect(() => {
    if (emailToken) {
      newsletterUnsubscribe();
    }
  }, [emailToken]);

  const [dataUnsubscribe, newsletterUnsubscribe] = useFetch({
    url: `/newsletter/unsubscribe`,
    requestBody: JSON.stringify({
      emailToken: emailToken,
    }),
  });

  const [dataSubscribe, newsletterSubscribe] = useFetch({
    url: `/newsletter/subscribe`,
    requestBody: JSON.stringify({
      emailToken: emailToken,
    }),
  });

  useEffect(() => {
    if (dataSubscribe?.code === 0) {
      setLoading(false);
      setDisabled(true);
      setAllowed(true);
    }
  }, [dataSubscribe]);

  const date = new Date();
  const formattedDate = new Intl.DateTimeFormat("ko-KR", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(date);

  return (
    <Stack
      sx={{
        width: "100%",
        height: `calc(var(--vh,1vh) * 100)`,
        py: "20px",
        px: "24px",
        maxWidth: "720px",
        mx: "auto",
      }}
      justifyContent="center"
    >
      <Stack
        sx={{ flex: 1 }}
        justifyContent="center"
        alignItems={"center"}
        spacing={2}
      >
        <CheckLottie containerSize={"72px"} />
        <Stack justifyContent="center" alignItems={"center"} spacing={2}>
          <Typography level="h4">
            {allowed ? "디스턴싱 컨텐츠 수신이 허용되었어요." : "디스턴싱 컨텐츠 수신거부가 완료되었어요."}
          </Typography>
          <Typography sx={{ opacity: 0.8 }} level="body-md">
            {`${formattedDate}`}
          </Typography>
          <Button
            variant={"Action"}
            loading={loading}
            disabled={disable}
            onClick={() => {
              if (emailToken) {
                setLoading(true);
                newsletterSubscribe();
              }
            }}
          >
            컨텐츠 다시 받아보기
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}