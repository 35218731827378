import Typography from "@mui/joy/Typography";
import { Box, Button, CircularProgress, Stack } from "@mui/joy";
import { MeditationItemType, SegmentationNoteItemType, ThoughtRecordItemType } from 'api/homeApi'
import NestedList, { menuKeyType } from "./NestedList";
import { TaskType } from "api";
import useOpenNewThoughtRecord from "hooks/useOpenNewThoughtRecord";
import useOpenNewMeditationRecord from "hooks/useOpenNewMeditationRecord";
import useOpenThoughtRecord from "hooks/useOpenThoughtRecord";
import useOpenMeditationRecord from "hooks/useOpenMeditationRecord";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import useIsInappWebview from "hooks/useIsInappWebview";
import {
  LearningActivityIcon,
  MeditationIcon,
  ThoughtRecordIcon,
  RightArrow, SegmentationRecordIcon,
} from 'static/image/common/IconAssets'
import useOpenSegmentationNote from '../../hooks/useOpenSegmentationNote'
import useOpenNewSegmentationNote from '../../hooks/useOpenNewSegmentationNote'
import { theme } from '../../styles/theme'

export default function ActivityList({
  title,
  isInitialLoading,
  listData,
}: {
  title: string;
  isInitialLoading: boolean;
  listData?: {
    listTitle: string;
    thoughtRecordList?: ThoughtRecordItemType[];
    meditationList?: MeditationItemType[];
    segmentationNoteList?: SegmentationNoteItemType[];
    taskList?: TaskType[];
    menuKey: menuKeyType;
    initiallyOpen?: boolean;
  }[];
}) {
  const user = useRecoilValue(userAtom);
  const isCoach = user?.role !== "patient";
  const isInappWebview = useIsInappWebview();

  const openThoughtRecord = useOpenThoughtRecord();
  const openMeditationRecord = useOpenMeditationRecord();
  const openNewThoughtRecord = useOpenNewThoughtRecord();
  const openNewMeditationRecord = useOpenNewMeditationRecord();

  const openSegmentationNote = useOpenSegmentationNote();
  const openNewSegmentationNote = useOpenNewSegmentationNote();

  const dataByTitle = [
    {
      title: "생각기록지",
      icon: <ThoughtRecordIcon />,
      onTitleClick: openThoughtRecord,
      buttonText: "기록하기",
      onButtonClick: openNewThoughtRecord,
      buttonDisabled: isCoach,
    },
    {
      title: "명상훈련지",
      icon: <MeditationIcon />,
      onTitleClick: openMeditationRecord,
      buttonText: "훈련하기",
      onButtonClick: openNewMeditationRecord,
      buttonDisabled: isCoach,
    },
    {
      title: "배움활동지",
      icon: <LearningActivityIcon />,
    },
    {
      title: "분리 노트",
      icon: <SegmentationRecordIcon />,
      onTitleClick: openSegmentationNote,
      buttonText: "분리하기",
      onButtonClick: openNewSegmentationNote,
      buttonDisabled: isCoach,
    },
  ];
  const currentData = dataByTitle.find((element) => element.title === title);

  return listData ? (
    <Stack
      sx={{
        width: "100%",
        mt: "8px",
        borderRadius: "12px",
        p: "20px",
        ...(isInappWebview && {
          backgroundColor: "white",
        }),
      }}
      spacing={"8px"}
    >
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          variant="Body"
          sx={{ fontWeight: 700, cursor: "pointer", color: theme.vars.palette.Content.Strong }}
          startDecorator={
            <Stack
              sx={{
                width: "20px",
                height: "20px",
                mr: "4px",
                borderRadius: "3px",
                // backgroundColor: "primary.solid",
              }}
              justifyContent="center"
              alignItems={"center"}
            >
              {currentData?.icon}
            </Stack>
          }
          {...(currentData?.onTitleClick && {
            endDecorator: (
              <Box
                sx={{
                  transform: "translateX(-4px)",
                }}
              >
                <RightArrow />
              </Box>
            ),
            onClick: currentData.onTitleClick,
          })}
        >
          {title}
        </Typography>
        {!isCoach && currentData?.buttonText && (
          <Button
            size="sm"
            variant="soft"
            disabled={currentData.buttonDisabled}
            onClick={() => {
              if (title === "생각기록지") {
                openNewThoughtRecord();
              } else if (title === "명상훈련지") {
                openNewMeditationRecord();
              } else if (title === "분리 노트") {
                openNewSegmentationNote()
              }
            }}
            sx={{ "--Button-gap": "0px", pl: "6px", pr: "12px", fontWeight: 700 }}
            startDecorator={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 10L5 10"
                  stroke={"currentColor"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 15L10 5"
                  stroke={"currentColor"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          >
            {currentData.buttonText}
          </Button>
        )}
      </Stack>

      {!isInitialLoading ? (
        listData.map(
          (
            {
              listTitle,
              thoughtRecordList,
              meditationList,
              segmentationNoteList,
              taskList,
              menuKey,
              initiallyOpen,
            },
            index
          ) => (
            <NestedList
              key={listTitle}
              menuKey={menuKey}
              title={listTitle}
              {...(thoughtRecordList && {
                thoughtRecordList: thoughtRecordList,
              })}
              {...(meditationList && { meditationList: meditationList })}
              {...(segmentationNoteList && { segmentationNoteList: segmentationNoteList })}
              {...(taskList && { taskList: taskList })}
              initiallyOpen={initiallyOpen}
            />
          )
        )
      ) : (
        <Stack
          justifyContent={"center"}
          alignItems="center"
          sx={{
            flexGrow: 1,
          }}
        >
          <CircularProgress
            sx={{
              mt: "14px",
              mx: "auto",
            }}
            color="neutral"
          />
        </Stack>
      )}
    </Stack>
  ) : (
    <></>
  );
}
