import { Box, Grid, Stack, Typography } from "@mui/joy";
import useOpenTask from "hooks/useOpenTask";
import {
  AutomaticThoughtForValueType,
  ConceptType,
  ValueBehaviorType,
} from "api/conceptApi";
import _ from "lodash";
import { ThoughtMapText } from "components/thoughtmap/ThoughtMapText";
import ThoughtMapCard from "components/thoughtmap/ThoughtMapCard";
import { ThoughtmapDownArrow } from "components/thoughtmap/ThoughtMap";

function ValueItem({
  step,
  text,
  taskKey,
}: {
  step?: number;
  text: string;
  taskKey: string;
}) {
  const openTask = useOpenTask(`${taskKey}`);
  return (
    <Stack
      direction={"row"}
      justifyContent="space-between"
      spacing="8px"
      alignItems={"center"}
      sx={{
        py: "12px",
        px: "10px",
        backgroundColor: "background.level1",
        borderRadius: "8px",
        cursor: "pointer",
        wordBreak: "break-word",
      }}
      onClick={openTask}
    >
      <Stack direction={"row"} alignItems={"center"} spacing="10px">
        {step !== undefined && (
          <Stack
            sx={{
              flexShrink: 0,
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              backgroundColor: "common.black",
            }}
            justifyContent="center"
            alignItems={"center"}
          >
            <Typography
              sx={{ fontSize: "10px", fontWeight: 500, color: "white" }}
            >
              {step}
            </Typography>
          </Stack>
        )}
        <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
          {text}
        </Typography>
      </Stack>

      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="20"
          y="0.500061"
          width="20"
          height="20"
          rx="3"
          transform="rotate(90 20 0.500061)"
          fill="#F4F6F8"
        />
        <path
          d="M8 15.5001L12 10.5001L8 5.50006"
          stroke="#7F8283"
          strokeOpacity="0.3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Stack>
  );
}

function AutomaticThoughtPattern({
  situation,
  thought,
  emotion,
  physicalResponse,
  behavior,
  value,
  valueTaskKey,
  valueBehaviorList,
}: {
  situation?: string;
  thought?: string;
  emotion?: string;
  physicalResponse?: string;
  behavior?: string;
  value?: string;
  valueTaskKey?: string;
  valueBehaviorList?: ValueBehaviorType[];
}) {
  return (
    <Stack spacing={2} sx={{ textAlign: "left" }}>
      <Stack
        spacing={2}
        sx={{
          textAlign: "left",
          pb: "20px",
          borderBottomWidth: "1px",
          borderColor: "divider",
        }}
      >
        {[
          { id2: "situation", label: "상황", content: situation },
          {
            id2: "thought",
            label: "자동적 사고",
            content: thought,
          },
          {
            id2: "emotion",
            label: "감정",
            content: emotion,
          },
          {
            id2: "physicalResponse",
            label: "신체 반응",
            content: physicalResponse,
          },
          { id2: "behavior", label: "행동", content: behavior },
        ].map(({ label, content }) => (
          <Stack key={`${label}${content}`} spacing={"8px"}>
            <ThoughtMapText text={label} dimmed color="primary.solid" />
            <ThoughtMapText text={content || ""} />
          </Stack>
        ))}
      </Stack>
      <Stack spacing={"8px"}>
        <ThoughtMapText text={"가치"} color="primary.solid" />
        <ValueItem text={value || ""} taskKey={`${valueTaskKey}`} />
      </Stack>
      {(valueBehaviorList || []).length > 0 && (
        <Stack spacing={"8px"}>
          <ThoughtMapText text={"가치 행동"} color="primary.solid" />
          <Stack spacing="8px">
            {valueBehaviorList?.map(({ taskKey, valueBehavior }, index) => (
              <ValueItem
                step={index + 1}
                text={valueBehavior || ""}
                taskKey={`${taskKey}`}
              />
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default function AutomaticThoughtForValue({
  data,
}: {
  data: ConceptType;
}) {
  const automaticThoughtList = [
    ...Object.entries(data)?.map(([id, value], index) => {
      return {
        id: id,
        label: "긍정 패턴",
        labelPostfix: `${index + 1}`,
        content: value as AutomaticThoughtForValueType,
      };
    }),
    {
      id: "extra",
      label: "긍정 패턴",
      labelPostfix: `${Object.entries(data).length + 1}`,
      content: {},
    },
  ];

  return (
    <Stack justifyContent={"center"}>
      <ThoughtmapDownArrow />

      <Grid container direction={"row"} spacing={2} flexGrow={1}>
        {automaticThoughtList.map(
          ({ id, label, labelPostfix, content }, index) => (
            <Grid
              key={id}
              sm={12 / Math.min(automaticThoughtList.length, 2)}
              xs={12}
              sx={{ display: "flex" }}
            >
              <ThoughtMapCard
                sx={{ flexGrow: 1 }}
                key={id}
                label={label}
                labelPostfix={labelPostfix}
                content={
                  Object.values(content || {}).every(
                    (each) =>
                      !each ||
                      (typeof each === "string" && each.trim().length === 0)
                  ) ? (
                    <ThoughtMapText
                      text={"일상에서 마주하는 나의 가치는?"}
                      dimmed
                    />
                  ) : (
                    <AutomaticThoughtPattern {...content} />
                  )
                }
                dimmed={Object.values(content || {}).every(
                  (each) =>
                    !each ||
                    (typeof each === "string" && each.trim().length === 0)
                )}
              />
            </Grid>
          )
        )}
      </Grid>
    </Stack>
  );
}
