import { useMutation } from "@tanstack/react-query";
import { paymentApi, StoreReviewerConfirmType } from "api/paymentApi";

export function useConfirmStoreReviewerPayment() {
  return useMutation(
    async (payload: StoreReviewerConfirmType) => {
      return await paymentApi.confirmStoreReviewer(payload);
    },
    {
      onSuccess: async (responseData, variables) => {},
      onError: (error) => {
        alert("결제가 실패했어요. 다시 시도해 주세요.");
      },
    }
  );
}