import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import { currentTaskSectionIndexAtom } from "recoil/currentTaskSectionIndexAtom";
import { layoutAtom } from "recoil/layoutAtom";
import { useNavigate } from "react-router-dom";
import useIsInappWebview from "./useIsInappWebview";
import { sendMessage } from "utils/webview";
import { userAtom } from "recoil/userAtom";
import useIsLatest from "./useIsLatest";
import { patientAtom } from "recoil/patientAtom";

export default function useOpenOnboarding() {
  const isLatest = useIsLatest();
  const navigate = useNavigate();
  const isInappWebview = useIsInappWebview();
  const currentIndex = useRecoilValue(currentTaskSectionIndexAtom);
  const [taskSectionState, setTaskSectionState] =
    useRecoilState(taskSectionAtom);
  const setLayoutState = useSetRecoilState(layoutAtom);
  const user = useRecoilValue(userAtom);
  const patientState = useRecoilValue(patientAtom);

  function goOnboarding() {
    if (isInappWebview) {
      sendMessage(
        JSON.stringify({
          event: "open_modal",
          params: {
            url: `${window.location.origin}/onboarding_new/load/1`,
            type: "transparentModal",
          },
        })
      );
    } else {
      navigate("/onboarding_new/load/1", { replace: true });
    }
  }

  return goOnboarding;
}