import React, { useEffect, useRef, useState } from 'react'
import { Box, Stack, Typography, useTheme } from '@mui/joy'
import playIcon from 'static/image/guide/Play.svg'
import pauseIcon from 'static/image/guide/Pause.svg'
import guide1Img from 'static/image/guide/guide_1.png'
import guide2Img from 'static/image/guide/guide_2.png'
import guide3Img from 'static/image/guide/guide_3.png'
import guide4Img from 'static/image/guide/guide_4.png'
import guide5Img from 'static/image/guide/guide_5.png'
import guide6Img from 'static/image/guide/guide_6.png'
import guide7Img from 'static/image/guide/guide_7.png'
import guide8Img from 'static/image/guide/guide_8.png'
import MarqueeLabel from './MarqueeLabel'
import { useParams } from 'react-router-dom'

// 예시 Step 정의
interface GuideStep {
  seq: number;
  title: string;
  description: string;
  backgroundColor: string;
  mainImg: string;
  audioSrc: string;
}

const steps: GuideStep[] = [
  {
    seq: 1,
    title: "주의력 되찾기",
    description: "‘나’의 주의를 한 곳에 집중시킵니다.",
    backgroundColor: "#222D6D",
    mainImg: guide1Img,
    audioSrc: "https://media.distancing.im/audio/body_scan_10min_ko.mp3",
  },
  {
    seq: 2,
    title: "생각 알아차리기",
    description:
      "생각을 ‘나'와 동일시하지 않고 하나의 심리적 사건으로 바라봅니다.",
    backgroundColor: "#158893",
    mainImg: guide2Img,
    audioSrc: "https://media.distancing.im/audio/body_scan_10min_ko.mp3",
  },
  {
    seq: 3,
    title: "반추 빠져나오기",
    description: "주의를 호흡에 집중하며 반추에서 빠져나옵니다.",
    backgroundColor: "#0F7630",
    mainImg: guide3Img,
    audioSrc: "https://media.distancing.im/audio/body_scan_10min_ko.mp3",
  },
  {
    seq: 4,
    title: "바디스캔",
    description: "‘나'의 위치를 지키며 신체의 각 부분을 섬세하게 알아차립니다.",
    backgroundColor: "#272E6A",
    mainImg: guide4Img,
    audioSrc: "https://media.distancing.im/audio/body_scan_10min_ko.mp3",
  },
  {
    seq: 5,
    title: "근육 이완",
    description:
      "‘‘나'의 위치를 지키며 전신의 근육을 수축하고 이완하며 긴장을 풉니다.",
    backgroundColor: "#559E87",
    mainImg: guide5Img,
    audioSrc: "https://media.distancing.im/audio/body_scan_10min_ko.mp3",
  },
  {
    seq: 6,
    title: "그라운딩",
    description:
      "신체가 땅과 맞닿아 있는 느낌을 알아차리며 ‘나'의 관점에서 신체 감각과 새롭게 관계를 맺습니다.",
    backgroundColor: "#747026",
    mainImg: guide6Img,
    audioSrc: "https://media.distancing.im/audio/body_scan_10min_ko.mp3",
  },
  {
    seq: 7,
    title: "기꺼이 경험하기",
    description:
      "마음속에 떠오르는 감정을 알아차리고 피하지 않으며 기꺼이 경험합니다.",
    backgroundColor: "#2F4344",
    mainImg: guide7Img,
    audioSrc: "https://media.distancing.im/audio/body_scan_10min_ko.mp3",
  },
  {
    seq: 8,
    title: "가치 확언하기",
    description:
      "주의를 가치로 돌리며 마음속에 떠오르는 가치를 더욱 명확하게 합니다.",
    backgroundColor: "#4488C4",
    mainImg: guide8Img,
    audioSrc: "https://media.distancing.im/audio/body_scan_10min_ko.mp3",
  },
];

// 시간(초)을 "분:초" 변환
function formatTime(seconds: number) {
  if (!seconds || seconds < 0) return "0:00";
  const m = Math.floor(seconds / 60);
  const s = Math.floor(seconds % 60);
  return `${m}:${s < 10 ? "0" + s : s}`;
}

export default function AudioGuide() {
  const { id } = useParams();

  const numId = parseInt(id || "1");

  const currentStepIndex = Math.min(Math.max(numId - 1, 0), steps.length - 1);

  const step = steps[currentStepIndex];
  const theme = useTheme();

  // 오디오 재생 제어
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0); // 전체 길이(초)
  const [currentTime, setCurrentTime] = useState(0);

  // 오디오 로드
  useEffect(() => {
    audioRef.current = new Audio(step.audioSrc);

    const handleLoaded = () => {
      if (!audioRef.current) return;
      setDuration(audioRef.current.duration);
    };
    const handleTimeUpdate = () => {
      if (!audioRef.current) return;
      setCurrentTime(audioRef.current.currentTime);
    };
    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
    };

    audioRef.current.addEventListener("loadedmetadata", handleLoaded);
    audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
    audioRef.current.addEventListener("ended", handleEnded);

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.removeEventListener("loadedmetadata", handleLoaded);
        audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        audioRef.current.removeEventListener("ended", handleEnded);
        audioRef.current = null;
      }
    };
  }, [step.audioSrc]);

  // 재생/일시정지
  const handleTogglePlay = () => {
    if (!audioRef.current) return;
    if (!isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const progressRatio = duration ? currentTime / duration : 0;
  const remainingTime = duration - currentTime;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        overflow: "auto",
        backgroundColor: step.backgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        pt: 8,
        pb: 8,
      }}
    >
      <Stack
        spacing={3}
        alignItems="center"
        sx={{
          width: "100%",
          maxWidth: 500,
          px: 2,
        }}
      >
        {/* 메인 이미지 (수평 중앙정렬) */}
        <Box
          sx={{
            width: 254,
            height: 254,
            borderRadius: 2,
            border:
              "0px solid var(--mono-colors-with-opacity-pure-white-4, #FFFFFF0A)",
            boxShadow: "0px 24px 44px -20px #0000003D",
            overflow: "hidden",
          }}
        >
          <img
            src={step.mainImg}
            alt="guide"
            style={{
              width: "100%",
              height: "100%",
              display: "block",
              objectFit: "cover",
            }}
          />
        </Box>

        {/* 제목 + 짧은 안내 문구(마키) */}
        <Stack spacing={0.5} alignItems="start" sx={{ width: "100%", px: 6, pt: 2}}>
          <Typography
            variant="Subtitle"
            sx={{
              fontWeight: 700,
              color: theme.vars.palette.Content.Negative,
              textAlign: "center",
            }}
          >
            {`${step.seq}. ${step.title}`}
          </Typography>
          <MarqueeLabel
            variant="Subtitle"
            sx={{
              fontWeight: 500,
              color: theme.vars.palette.Content.Negative,
              opacity: 0.6,
            }}
            text={step.description}
          />
        </Stack>

        {/* 오디오 플레이어 UI */}
        <Stack spacing={2} alignItems="center" sx={{ width: "100%", px: 6, pt: 2}}>
          {/* ➋ 진행바 위, 시간은 아래로 */}
          <Box
            sx={{
              width: "100%",
              height: 6,
              backgroundColor: "rgba(255,255,255,0.3)",
              borderRadius: 3,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: `${progressRatio * 100}%`,
                height: "100%",
                backgroundColor: "#fff",
                borderRadius: 3,
                transition: "width 0.2s linear",
              }}
            />
          </Box>

          {/* 시간 (왼쪽=현재, 오른쪽=남은) */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography
              variant="Footnote"
              sx={{
                fontWeight: 700,
                color: theme.vars.palette.Content.Negative,
              }}
            >
              {formatTime(currentTime)}
            </Typography>
            <Typography
              variant="Footnote"
              sx={{
                fontWeight: 700,
                color: theme.vars.palette.Content.Negative,
              }}
            >
              -{formatTime(remainingTime)}
            </Typography>
          </Stack>

          {/* 재생 버튼 */}
          <Box
            sx={{
              width: 64,
              height: 64,
              borderRadius: "50%",
              backgroundColor: "rgba(255,255,255,0.15)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleTogglePlay}
          >
            <img
              src={isPlaying ? pauseIcon : playIcon}
              alt="play_or_pause"
              style={{ width: 28, height: 28 }}
            />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
