import React from "react";
import { Box, Button, Stack } from "@mui/joy";
import TitleWithSubtitle from "components/common/TitleWithSubtitle";
import ProgressBar from "components/common/ProgressBar";
import FullPageContainer from "./FullPageContainer";
import { theme } from "styles/theme";
import { extractHexColor } from "styles/hexOpacity";

export default function FullPageWithTitleAndButton({
  title,
  subtitle,
  image,
  content,
  buttonText,
  buttonDisabled,
  buttonClickAction,
  progress = 0,
}: {
  title: string;
  subtitle?: string;
  image?: string;
  content?: React.ReactNode;
  buttonText: string;
  buttonDisabled?: boolean;
  buttonClickAction: () => void;
  progress?: number;
}) {
  const backgroundColor = extractHexColor(theme.vars.palette.Background.Level1);
  return (
    <FullPageContainer backgroundColor={backgroundColor}>
      <Stack
        direction={"column"}
        alignItems="center"
        justifyContent={"center"}
        spacing={2}
        sx={{
          maxWidth: "400px",
          mx: "auto",
          height: "100%",
        }}
      >
        <Stack sx={{ width: "100%", pb: "20px", px: "20px" }}>
          <ProgressBar progress={progress} />
        </Stack>
        <Stack sx={{ px: "20px", position: "relative", zIndex: 100 }}>
          <TitleWithSubtitle
            title={title}
            subtitle={subtitle}
            sx={{ textAlign: "center"}}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: -20,
              width: "100%",
              height: "20px",
              background: `linear-gradient(0deg, ${backgroundColor}00 0%, ${backgroundColor} 100%)`,
            }}
          />
        </Stack>
        <Stack
          sx={{
            pt: "20px",
            width: "100%",
            flex: 1,
            overflow: "auto",
            px: "20px",
            position: "relative",
          }}
        >
          {image ? (
            <Stack
              direction="column"
              justifyContent={"center"}
              sx={{ flex: 1, pb: "20px" }}
            >
              <img
                src={image}
                alt={"onboarding"}
                // style={{ objectFit: "contain" }}
              />
            </Stack>
          ) : (
            content
          )}
        </Stack>
        <Stack
          sx={{
            px: "20px",
            width: "100%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: -20,
              width: "calc(100% - 40px)",
              height: "20px",
              background: `linear-gradient(180deg, ${backgroundColor}00 0%, ${backgroundColor} 100%)`,
            }}
          />
          <Button
            size="lg"
            sx={{ width: "100%" }}
            disabled={buttonDisabled}
            onClick={buttonClickAction}
          >
            {buttonText}
          </Button>
        </Stack>
      </Stack>
    </FullPageContainer>
  );
}