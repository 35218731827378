import { Box, CircularProgress, Stack, Typography } from "@mui/joy";
import useMixpanelWeb from "hooks/useMixpanelWeb";
import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import DefaultPage from "pages/container/DefaultPage";
import AppleLoginButton from "components/user/AppleLoginButton";
import GoogleLoginButton from "components/user/GoogleLoginButton";
import login_main from "static/image/login/login_main.svg";
import { isMobile } from "react-device-detect";
import { theme } from "styles/theme";
import CustomTypography from "components/common/CustomTypography";
import useSafeproLogin from "hooks/useSafeproLogin";
import { sendMessage } from "utils/webview";
import useIsInappWebview from "../../hooks/useIsInappWebview";

export default function Login() {
  const navigate = useNavigate();
  const user = useRecoilValue(userAtom);
  const [searchParams] = useSearchParams();
  const { state } = useLocation();

  const isInappWebview = useIsInappWebview();

  const isOnboarding = searchParams.get("type") === "1"; // 신규모객

  const isSafeproLoginRequired = useSafeproLogin();

  // Mixpanel 이벤트 예시
  const setMixPanel_new = useMixpanelWeb({ eventName: "0-0-A로 바로 진입" });
  const setMixPanel_come = useMixpanelWeb({
    eventName: "로그인 진입",
    params: {
      type: isMobile ? "app" : "web",
      ...(user?.appId && { appId: user?.appId }),
    },
  });

  useEffect(() => {
    if (!user?.accessToken) {
      if (isOnboarding) {
        setMixPanel_new();
        navigate("/", { replace: true, state: { isOnboarding: true } });
      }
    } else {
      // 이미 로그인이 되어 있다면 메인으로 이동
      navigate("/", { ...state, replace: true });
    }
  }, [user]);

  useEffect(() => {
    setMixPanel_come();
  }, []);

  // useEffect(() => {
  //   if (isInappWebview) {
  //     sendMessage(
  //       JSON.stringify({
  //         event: "request_pushNotification",
  //         params: {
  //           enableGoToSetting: false,
  //           disableRequest: false,
  //         },
  //       })
  //     );
  //   }
  // }, []);

  return (
    <DefaultPage
      backgroundColor={theme.vars.palette.Background.Level2}
      content={
        /* 1) 화면 전체를 100vh로 잡아서 스크롤 없이 사용 */
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
            height: "80vh" /* 뷰포트 전체 높이 */,
            boxSizing: "border-box",
            overflow: "auto",
          }}
        >
          {/* 2) 이미지 영역: 최대 높이를 40%로 제한 */}
          <Box
            sx={{
              width: "100%",
              maxHeight: "40%" /* 화면의 50% 이상 차지하지 않도록 제한 */,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexShrink: 0,
            }}
          >
            <img
              src={login_main}
              alt="empty"
              style={{
                maxWidth: "100%",
                maxHeight: "100%" /* 부모 Box의 40% 높이를 넘기지 않음 */,
                objectFit: "contain",
              }}
            />
          </Box>

          {/* 3) 텍스트 영역 */}
          <Stack spacing={4} sx={{ textAlign: "center", flexShrink: 0 }}>
            <CustomTypography
              variant="H1"
              sx={{
                color: theme.vars.palette.Content.Strong,
                fontWeight: 800,
              }}
            >
              {"효과가 입증된\n지속가능한 멘탈케어"}
            </CustomTypography>
            <CustomTypography
              variant="Subtitle"
              sx={{
                color: theme.vars.palette.Content.Medium,
                fontWeight: 500,
              }}
            >
              {"전담 코치가 이끄는 맞춤 프로그램으로\n새로운 삶을 맞이하세요."}
            </CustomTypography>
          </Stack>

          {/* 4) 로그인 버튼 영역 */}
          <Stack
            spacing={1}
            sx={{
              width: "100%",
              maxWidth: "500px",
              flexShrink: 0,
              paddingBottom: "auto",
            }}
            alignSelf="center"
          >
            {isSafeproLoginRequired ? (
              <Stack alignItems={"center"}>
                <Typography
                  variant={"Body"}
                  sx={{
                    fontWeight: 500,
                    color: theme.vars.palette.Content.Medium,
                  }}
                >
                  {"자동 로그인 중..."}
                </Typography>
                <CircularProgress color="neutral" />
              </Stack>
            ) : (
              <>
                <AppleLoginButton />
                <GoogleLoginButton />
              </>
            )}
          </Stack>
        </Stack>
      }
    />
  );
}
