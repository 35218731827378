import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { onboardingApi } from "api/onboardingApi";
import { useNavigate } from "react-router-dom";
import { findStep } from "../../OnboardingScreen";

export function OnboardingLoad() {
  const navigate = useNavigate();

  // ① 서버에서 lastPage 불러오기

  const {
    data: resp,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(["onboardingLastPage"], () => onboardingApi.loadLastPage(), {
    cacheTime: 0, // 캐싱 비활성화
  });

  useEffect(() => {
    // 로딩 완료 + 데이터가 있다면
    if (isSuccess && resp?.result) {
      const finalPage = resp?.result?.pageId;

      if (finalPage) {
        const [type_, seq_ = "1"] = finalPage.split("_");
        const seqById_ = parseInt(seq_, 10);

        const step = findStep(type_, seqById_);

        // step이 제대로 찾았는지 확인
        if (step) {
          navigate(`/onboarding_new/${step.type}/${step.seqByType}`, {
            replace: true,
          });
        } else {
          // 못 찾았으면 기본 경로로 이동
          navigate("/onboarding_new/acquisition/1", { replace: true });
        }
      } else {
        // finalPage가 없으면 첫 단계로
        navigate("/onboarding_new/acquisition/1", { replace: true });
      }
    }
  }, [isSuccess]);

  if (isLoading) return <></>;
  if (isError) return <></>;
  return <></>;
}