import { Button } from "@mui/joy";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { deviceAtom } from "recoil/deviceAtom";
import { sendMessage } from "utils/webview";
import * as _ from "lodash";
import { ProgramContentType } from "data/BlockComponent";
import { userAtom } from "recoil/userAtom";
import { setProgramContentData } from "logic/logics";

export default function RequestPushNotificationButton({
  setData,
  blockIndex,
  lineIndex,
  cellIndex,
  showFromIdIfDenied,
  disabled,
  clicked,
}: {
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  blockIndex: number;
  lineIndex: number;
  cellIndex: number;
  showFromIdIfDenied?: string;
  disabled: boolean;
  clicked?: boolean;
}) {
  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });
  const userState = useRecoilValue(userAtom);
  const isCoach = userState?.accessToken && userState?.role !== "patient";
  const [deviceState, setDeviceState] = useRecoilState(deviceAtom);
  const isGranted = clicked && deviceState.pushNotificationStatus === "granted";

  useEffect(() => {
    if (!isCoach) {
      if (deviceState.pushNotificationStatus !== "granted") {
        setData((data) => {
          if (data) {
            const data_temp = _.cloneDeep(data);
            data_temp[blockIndex].nextBlockId = showFromIdIfDenied;
            return data_temp;
          }

          return data;
        });
      } else {
        setData((data) => {
          if (data) {
            const data_temp = _.cloneDeep(data);
            data_temp[blockIndex].nextBlockId = undefined;
            return data_temp;
          }

          return data;
        });
      }
    }
  }, [deviceState]);

  return (
    <Button
      fullWidth
      onClick={() => {
        sendMessage(
          JSON.stringify({
            event: "request_pushNotification",
            params: {
              enableGoToSetting: true,
              disableRequest: false,
            },
          })
        );
        setProgramContentData({
          setData,
          blockIndex,
          lineIndex,
          cellIndex,
          newlyAddedData: {
            clicked: true,
          },
        });
        setDeviceState((user) => ({
          ...user,
          pushNotificationStatus: "not set",
        }));
      }}
      disabled={isGranted || disabled}
    >
      {t_ui(!isGranted ? "알림 허용하기" : "알림 허용됨")}
    </Button>
  );
}
