import { Button } from "@mui/joy";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { sendMessage } from "utils/webview";
import useIsInappWebview from "hooks/useIsInappWebview";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";
import { useCallback, useState } from "react";
import { USER_DETAIL_ENDPOINT, USER_DOMAIN, userApi } from "api/userApi";
import ButtonWithModal from "components/common/ButtonWithModal";
import { paymentOrderAtom } from "recoil/paymentOrderAtom";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "api";
import { useConfirmCompanyMemberPayment } from "hooks/useConfirmCompanyMemberPayment";

export default function PaymentButtonCompanyMember({
  color = "primary",
  size = "lg",
}: {
  color?: "primary" | "white";
  size?: "sm" | "lg";
}) {
  const isInappWebview = useIsInappWebview();

  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const paymentOrderState = useRecoilValue(paymentOrderAtom);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const {
    mutate: confirmCompanyMember,
    isLoading: isLoadingConifirmReviewer,
    isSuccess,
    isError,
  } = useConfirmCompanyMemberPayment();

  useQuery(
    [USER_DOMAIN, USER_DETAIL_ENDPOINT],
    useCallback(() => userApi.getDetail(), []),
    {
      enabled:
        !!axiosInstance.defaults.headers["X-Auth-Token"] && !!user?.accessToken,

      onSuccess: (data) => {
        if (data && data.result) {
          //결제 탭 닫기
          if (data.result.subscription?.isSubscribing) {
            setDisabled(true);

            if (!user?.subscription?.isSubscribing) {
              // 닫기 토스페이먼츠 창 닫기
              sendMessage(
                JSON.stringify({
                  event: "navigate_back",
                })
              );
            }
          }

          setUser((user) => ({
            ...user,
            ...data.result,
          }));
        }
      },
    }
  );

  const { isNotStarted, hasFreeTrialUsed } = useSubscriptionStatus();
  const buttonText = isNotStarted ? "프로그램 시작하기" : "계속 이용하기";

  const requestPayment = () => {
    if (paymentOrderState) {
      setIsLoading(true);

      confirmCompanyMember(
        { orderId: paymentOrderState?.orderId },
        {
          onSuccess: () => {
            if (isInappWebview) {
              setIsLoading(false);

              setDisabled(true);

              sendMessage(
                JSON.stringify({
                  event: "navigate_back",
                })
              );
            } else {
              // 웹앱이면 메인화면으로
              navigate("/", { replace: true });
            }
          },
        }
      );

      return;
    }
  };

  const { mutate: reassignCoach } = useMutation(() => userApi.reassignCoach(), {
    onSuccess: () => {},
  });

  return user?.isCoachChanged ? (
    <ButtonWithModal
      size={size}
      color={color}
      buttonFullWidth
      buttonText={buttonText ?? ""}
      disabled={disabled}
      loading={isLoading}
      title={"담당 코치가 변경되었어요"}
      subtitle={`${user?.assignedCoachName} 선생님은 더이상 디스턴싱과 함께하지 않게 되었어요.`}
      textAlign="center"
      actionText="새 코치 만나기"
      action={() => {
        reassignCoach();
        requestPayment();
      }}
    />
  ) : (
    <Button
      loading={isLoading}
      disabled={disabled}
      onClick={requestPayment}
      size={size}
      color={color}
      sx={{ width: "100%" }}
    >
      {buttonText}
    </Button>
  );
}